/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

svg {
  vertical-align: baseline;
}

@import "~react-datepicker/dist/react-datepicker.css";
